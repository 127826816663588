<template>
  <div class="checkpending">
    <div v-if="nosite" class="nodata-wrap">
      <img :src="nodata" alt="" class="nodata-img">
      <div>暂无待审批站点</div>
    </div>
    <div v-else
         class="site-item flex"
         v-for="(item, index) in siteList"
         :key="index"
         @click="siteDetailClick(item)">
      <img class="sitezz-img"
           :src="sitezz"
           alt="">
      <div class="site-item_right flex">
        <div class="site-item_one flex">
          <div class="siteName">{{item.stationName | ellipsis11}}</div>
          <div class="siteStatue">{{item.stationStatus}}</div>
        </div>
        <div class="site-item_two">{{item.stationLocate}}</div>
        <div class="site-item_three flex">
          <div v-if="item.stationTag">{{item.stationTag}}</div>
          <div v-if="item.performanceRange">{{item.performanceRange}}</div>
        </div>
        <div class="seeDetail"><b class="btn">查看站点</b></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getPendingStationList } from '@/utils/api'
import common from '@/utils/common'
import {Page_Audit_checkpending} from "../../store/mutation-types";
import {appAction, uwStatisticAction} from "../../utils/util";
import {CHANGE_AGENT_AUDIT} from "../../utils/constantNum";
export default {
  components: {
    [Toast.name]: Toast
  },
  filters: {
    ellipsis11 (value) {
      if (!value) return ''
      if (value.length > 11) {
        return value.slice(0, 11) + '...'
      }
      return value
    },
  },
  data () {
    return {
      logoImg: require('../../assets/img/logo_f.png'),
      siteList: [],
      nosite: true,
      sitezz: require('@/assets/img/site/sitezz.png'),
      nodata: require('@/assets/img/nodata/nodata.png'),
    }
  },
  mounted () {
    this.getSiteList()
    uwStatisticAction('/site/checkpending','待审核站点')
  },
  activated() {
    this.getSiteList()
  },
  methods: {
    siteDetailClick (item) {
      if(item.flowKey == 'StationBackFlow'){ // 退回考察中
        let p = {
          applyNo: item.applyNo,
          stationNo: item.stationNo
        }
        appAction(CHANGE_AGENT_AUDIT, p)
      }else{
        this.$router.push({ name: 'audit', applyNo: item.applyNo, query: { pending: JSON.stringify(item), stationNo: item.stationNo, applyNo: item.applyNo ,from:Page_Audit_checkpending} })
      }
    },
    getSiteList () {
      getPendingStationList({
        token: common.getItem('wtToken'),
        pageIndex: 1,
        pageSize: 20
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.data && res.data.businessCode) {
            this.nosite = false
            this.siteList = res.data.data.list
          } else {
            this.nosite = true
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.checkpending {
  height: 100%;
  padding: 12px;
  background: #F7F8F9;
  .flex {
    display: flex;
  }
  .sitezz-img {
    width: 1.36rem;
    height: 1.36rem;
    flex: 0 0 1.36rem;
  }
  .nodata-img{
    width: 60%;
    margin-top: 1rem;
  }
  .nodata-wrap{
    padding-top: 0.5rem;
    text-align: center;
    color: #616161;
    font-size: 0.3rem;
  }
  .check-pending {
    width: 100%;
    background: #f5f5f5;
    color: #1989fa;
    height: 30px;
    line-height: 30px;
  }
  .status-list {
    .van-button {
      flex: 1;
    }
    .van-button--normal {
      padding: 0 !important;
    }
  }
  .site-item {
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 15px;
    margin: 12px 0 12px 0;
    .logo-img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.3rem;
    }
    .site-item_right {
      margin-left: 0.2rem;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      .site-item_one {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .siteName {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .siteStatue {
          background: #FFF8E9;
          border-radius: 2px;
          padding: 3px 9px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #F5A623;
        }
      }
      .site-item_two {
        word-wrap: break-word;
        word-break: break-all;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 6px 0;
      }
      .site-item_three {
        div {
          border-radius: 4px;
          border: 1px solid#D9E4DD;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3F7C53;
          padding: 1px 10px;
        }
      }
      .seeDetail {
        text-align: right;
        .btn {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          background: #3F7C53;
          border-radius: 12px;
          display: inline-block;
          padding: 4px 10px;
        }
      }
    }
  }
}
</style>
